import moment from 'moment-timezone';

export const formatDateTime = (
  dateTimeInUTC,
  format = 'hh:mm A (z) dddd, MMMM Do, YYYY',
  timezone = moment.tz.guess()
) => {
  if (!dateTimeInUTC) return '';

  const date = moment.utc(dateTimeInUTC).tz(timezone);

  return date.format(format);
};

// function to delete cookies
export const deleteCookies = () => {
  const Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString();
};

export const sortArray = (data, key, sortingOrder = 1) => {
  return (
    data?.slice()?.sort((a, b) => {
      const firstValue =
        typeof a[key] === 'string' ? a[key]?.toLowerCase() : a[key];
      const secondValue =
        typeof b[key] === 'string' ? b[key]?.toLowerCase() : b[key];

      if (firstValue < secondValue) {
        return sortingOrder * -1;
      }
      if (firstValue > secondValue) {
        return sortingOrder * 1;
      }
      return 0;
    }) || []
  );
};

export const sortByDate = (data, sortingOrder = 1) => {
  return (
    data?.slice()?.sort((a, b) => {
      const aDate = a?.modified_at || a?.created_at;
      const bDate = b?.modified_at || b?.created_at;

      if (aDate && bDate) {
        return (
          (moment(aDate).format('x') - moment(bDate).format('x')) * sortingOrder
        );
      }

      return 0;
    }) || []
  );
};

export const getComparator = order => {
  return order === 'desc' ? -1 : 1;
};

export const allPropHasValue = propsObject => {
  return Object.keys(propsObject)?.every(
    item =>
      typeof propsObject[item] !== 'undefined' &&
      propsObject[item] !== null &&
      propsObject[item] !== ''
  );
};

export const validateStartEndDate = (start, end) => {
  if (start && end && moment(end).diff(start, 'd') < 0) {
    return false;
  }

  return true;
};

export const ConvertToNumber = string => {
  return string === '' ||
    string === null ||
    typeof string === 'undefined' ||
    isNaN(string)
    ? ''
    : parseFloat(string);
};

export const getUserNameTags = string => {
  const words = string?.split(' ') || [];

  return (
    words
      ?.filter(word => word?.startsWith('@'))
      ?.map(word => word?.replace('@', '')) || []
  );
};

export const prepareQueryString = params => {
  return Object.entries(params)
    ?.map(([field, value]) => {
      value = value === 'all' ? '' : value;
      return `${field}=${encodeURIComponent(value || '')}`;
    })
    .filter(query => /^.+=.+$/.test(query))
    .join('&');
};

export const formatCredit = (credit) => {

  const creditPoint = (parseInt(credit)/10).toFixed(1);
  console.log(typeof(creditPoint),'creditPoint');

  return (isNaN(creditPoint) || creditPoint === "0.0") ? '0' : creditPoint;

}

export const formatTime = (time) => {
  try {
    return time ? time.substring(0, 2) + ":" + time.substring(2, 4) : null;
  } catch (err) {
    console.log(err);
  }
  return null;
}
